import { Projects } from "./Projects";

export function CodedProjects() {

	return (
		<div>
			<Projects></Projects>
		</div>
	);
}
  